<template>
    <el-card class="box-card">
        <el-button type="primary" class="d-block mr-0 ml-auto" icon="el-icon-plus" @click="addPage">
            {{ $t('profile.add-page') }}
        </el-button>
        <div class="separator"></div>
        <el-table :data="pages" style="width: 100%" size="small" border>
            <el-table-column :label="$t('profile.page-name')">
                <template slot-scope="scope">
                    <span :style="scope.row.breach ? 'color:firebrick;font-weight:bold;' : 'color:#606266;'">{{
                        scope.row.nume_pagina
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('general.actions')" width="185">
                <template slot-scope="scope">
                    <el-button class="b-c-ep" type="primary" size="mini" plain @click.native="editPage(scope.row)">
                        {{ $t('general.edit') }}</el-button
                    >
                    <el-button class="b-c-dp" type="danger" size="mini" plain @click.native="deletePage(scope.row)">
                        {{ $t('general.delete') }}</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <el-dialog
            v-if="dialogVisible"
            :title="modalTitle"
            :visible.sync="dialogVisible"
            custom-class="page-dialog"
            fullscreen
        >
            <div>
                <div>
                    <div class="sub-title">{{ $t('profile.title') }}</div>
                    <el-input v-model="pageTitle" @blur="createSlug"></el-input>
                </div>
            </div>
            <div>
                <div>
                    <div class="sub-title">
                        Slug
                        <span style="font-size: 11px">({{ $t('profile.slug-desc') }})</span>
                    </div>
                    <el-input v-model="pageSlug"></el-input>
                </div>
            </div>
            <div>
                <div class="sub-title">{{ $t('profile.content') }}</div>
                <Editor :text.sync="pageContent"></Editor>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="b-c-dv" @click="dialogVisible = false">{{ $t('general.cancel') }}</el-button>
                <el-button class="b-c-sp" type="primary" @click="savePage">{{ $t('general.save') }}</el-button>
            </span>
        </el-dialog>
    </el-card>
</template>

<script>
import { slugify, debugPromise } from '@/utils/index';
const Editor = () => import('@/components/Editor');

import { get, sync, call } from 'vuex-pathify';
import cloneDeep from 'lodash.clonedeep';
export default {
    components: {
        Editor,
    },
    data() {
        return {
            selectedPage: 0,
            dialogVisible: false,
            page: {
                title: '',
                slug: '',
                content: '',
            },
        };
    },
    methods: {
        getPages: call('account/GetAffiliatePublicPages'),
        saveAffiliatePage: call('account/SaveAffiliatePage'),
        deleteAffiliatePage: call('account/DeleteAffiliatePage'),
        editPage(row) {
            const page = cloneDeep(row);
            this.pageId = page.id || 0;
            this.pageTitle = page.nume_pagina;
            this.pageSlug = page.pkey;
            if (page.continut_pagina) this.pageContent = page.continut_pagina;
            else this.pageContent = '';
            this.dialogVisible = true;
        },
        async addPage() {
            await this.resetDialog();
            this.dialogVisible = true;
        },
        resetDialog() {
            this.dialogVisible = false;
            return this.$store.set('account/informations@pages.pageDialog', {
                id: 0,
                title: '',
                slug: '',
                content: '',
            });
        },
        async savePage() {
            if (this.pageTitle.length < 3) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('profile.error-title'),
                });
                return false;
            }

            if (this.pageSlug.length < 3) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('profile.error-slug'),
                });
                return false;
            }

            if (this.pageContent.length < 3) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('profile.error-content'),
                });
                return false;
            }

            this.pageSlug = slugify(this.pageSlug);
            try {
                await this.saveAffiliatePage();
                this.resetDialog();
                this.sbMsg({
                    type: 'success',
                    message: this.$t('general.request-success'),
                });
            } catch (err) {
                this.$reportToSentry(e, {
                    extra: {
                        fn: 'savePage',
                        params: get('account/informations@pages.pageDialog'),
                    },
                });
            }
        },
        deletePage(row) {
            if (typeof row.id === 'undefined') {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('profile.error-delete'),
                });
                return false;
            }

            this.$confirm(this.$t('profile.confirm-delete'), this.$t('general.warning'), {
                confirmButtonText: this.$t('general.yes'),
                cancelButtonText: this.$t('general.no'),
                type: 'warning',
            })
                .then(() => {
                    return this.deleteAffiliatePage({
                        id: row.id,
                    });
                })
                .then(() =>
                    this.sbMsg({
                        type: 'success',
                        message: this.$t('general.request-success'),
                    })
                )
                .catch((err) => {
                    debugPromise('deletePage1', err);
                });
        },
        createSlug() {
            this.pageSlug = slugify(this.pageTitle);
        },
    },
    computed: {
        ...sync('account/informations@pages.pageDialog', {
            pageId: 'id',
            pageTitle: 'title',
            pageSlug: 'slug',
            pageContent: 'content',
        }),
        pages: get('account/informations@pages.list'),
        pagesWithProblems: get('account/informations@pages.withProblems'),
        modalTitle() {
            return this.selectedPage > 0 ? this.$t('profile.edit-page') : this.$t('profile.add-page');
        },
    },
    mounted() {
        this.getPages();
    },
};
</script>

<style>
.tox.tox-silver-sink.tox-tinymce-aux {
    z-index: 999999 !important;
}
</style>

